import React, { useCallback, useState, useEffect } from "react"
import {
  TextField,
  Grid,
  FormControl,
  Button,
  CircularProgress,
  Paper
} from "@material-ui/core"
import { useForm } from "react-hook-form"
import { useIntl } from "../../intl"
import styled from "styled-components"
import { postContactForm } from "../../api"
import secrets from "../../../secrets.json"

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  )
}

const HCSSInput = styled.input`
  display: none;
`

const SubmitWrapper = styled.div`
  display: inline;
  position: relative;
`

const StyledForm = styled.form`
  position: relative;
`

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -12px;
`;

const StyledResponse = styled(Paper)`
  box-shadow: 0px 0px 50px 0px rgb(214, 214, 214);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: center;
`

const Response = ({ response }) => {
  const intl = useIntl();
  return (
    <StyledResponse response={response}>
      {!response.error
        ? <span>{ intl.formatMessage({ id: "contact_form_response_success_msg" }) }</span>
        : <span>{ intl.formatMessage({ id: "contact_form_response_error_msg" }) }</span>}
    </StyledResponse>
  )
}

const HJSInput = React.forwardRef(({ ...props }, ref) => {
  const callback = useCallback(
    node => {
      if (node) {
        node.style.display = "none"
        if (ref.current) ref.current = node
        else if (isFunction(ref)) {
          ref(node)
        }
      }
    },
    [ref]
  )
  return <input ref={callback} {...props} />
})

const ContactForm = ({ className, ...props }) => {
  const intl = useIntl()

  const [submitted, setSubmitted] = useState(undefined)
  const [response, setResponse] = useState(undefined)

  const { handleSubmit, register, errors } = useForm()

  const doSubmit = useCallback(
    values => {
      setSubmitted(values)
    },
    [setSubmitted]
  )

  useEffect(() => {
    if (submitted) {
      postContactForm(submitted)
        .then(response => response.json())
        .then(setResponse)
    }
  }, [submitted])

  return (
    <StyledForm
      className={className}
      onSubmit={handleSubmit(doSubmit)}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled={!!submitted}
              id="contact_form_name"
              name="name"
              error={!!errors["name"]}
              label={intl.formatMessage({ id: "contact_form_name_label" })}
              variant="outlined"
              inputRef={register({
                required: intl.formatMessage({
                  id: "contact_form_name_required_error_msg",
                }),
              })}
              helperText={errors["name"]?.message}
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled={!!submitted}
              id="contact_form_email"
              name="email"
              error={!!errors["email"]}
              label={intl.formatMessage({ id: "contact_form_email_label" })}
              variant="outlined"
              inputRef={register({
                required: intl.formatMessage({
                  id: "contact_form_email_required_error_msg",
                }),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: intl.formatMessage({
                    id: "contact_form_email_invalid_error_msg",
                  }),
                },
              })}
              helperText={errors["email"]?.message}
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled={!!submitted}
              id="contact_form_company"
              name="company"
              label={intl.formatMessage({ id: "contact_form_company_label" })}
              variant="outlined"
              inputRef={register()}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              required
              disabled={!!submitted}
              id="contact_form_message"
              name="message"
              error={!!errors["message"]}
              label={intl.formatMessage({ id: "contact_form_message_label" })}
              rows={8}
              multiline
              variant="outlined"
              inputRef={register({
                required: intl.formatMessage({
                  id: "contact_form_message_required_error_msg",
                }),
              })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <SubmitWrapper>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!!submitted}
            >
              {intl.formatMessage({ id: "contact_form_submit_msg" })}
            </Button>
            { submitted && (<StyledCircularProgress color="secondary" size={24} />) }
          </SubmitWrapper>
        </Grid>
        {/* These are honeypot fields, made to lure out spambots, so we can eliminate them in the server */}
        <HCSSInput
          name={secrets.BLANK_HONEY_FIELD}
          type="text"
          ref={register()}
        />
        <HJSInput
          name={secrets.FILLED_HONEY_FIELD}
          type="text"
          defaultValue={secrets.FILLED_HONEY_FIELD_VALUE}
          ref={register()}
        />
      </Grid>
      { response && <Response response={response} /> }
    </StyledForm>
  )
}

export default ContactForm
