import React from "react";
import { Container } from "@material-ui/core";
import Footer from "./footer";
import Navigation from "./navigation";
import styled from "styled-components";

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

const LayoutContent = styled(Container)`
  flex: 1;
  width: 100%;
  padding: ${props => props.theme.spacing(2)}px;
`;

const Layout = ({ className, children, ...props }) => (
  <LayoutWrapper className={className}>
    <Navigation />
    <LayoutContent maxWidth="lg">{children}</LayoutContent>
    <Footer />
  </LayoutWrapper>
);

export default Layout;