import React from "react";
import { Card, CardContent, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import styled from "styled-components";
import Address from "./address";
import Map from "./map";
import AspectRatio from "./util/aspect-ratio"


const WrapperGrid = styled(Grid)`
  height: 100%;
`;

const StyledMap = styled(Map)`
  width: 100%;
  height: 100%;
`;


const StyledAspectRatio = styled(AspectRatio)`
  width: 100%;
  min-height: 100%;
`;

const MapWrapper = ({ children }) => {
  const theme = useTheme(); 
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return isSmall ? (
    <StyledAspectRatio ratio={16/9}>
      { children }
    </StyledAspectRatio>
  ) : children;
}

const AddressCard = ({ node, className }) => {
  const { lat, lng } = node.childMarkdownRemark.frontmatter;
  
  return (
    <Card className={className}>
      <WrapperGrid container>
        <Grid item xs={4}>
          <CardContent>
            <Address node={node} />
          </CardContent>
        </Grid>
        <Grid item xs={8}>
          <MapWrapper>
            <StyledMap lat={lat} lng={lng} zoom={15} showMarker />
          </MapWrapper>
        </Grid>
      </WrapperGrid>
    </Card>
  );
};

export default AddressCard;