import React from "react"
import { Typography, Grid, Box } from '@material-ui/core';
import { useIntl } from "../intl";
import styled from "styled-components";
import BasePage from "../components/base-page";
import Layout from "../components/layout";
import AddressesSource from "../datasources/addresses";
import ContactForm from "../components/contact-form";
import AddressCard from "../components/address-card";
import GoogleMapsProvider from "../google-maps/google-maps-provider";

const StyledContainer = styled(Grid)`
  min-height: 100%;
  justify-self: center;
`;

const AddressesWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledAddressCard = styled(AddressCard)`
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 50px 0px rgb(214, 214, 214);
  :not(:last-child) {
    margin-bottom: ${props => props.theme.spacing(2)}px;
  }
`;


const ContactPage = (props) => {
  const intl = useIntl();
  return (
    <BasePage {...props}>
      <GoogleMapsProvider apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}>
        <Layout>
          <StyledContainer container spacing={5} alignItems="center">
            <Grid item xs={12} lg={6}>
              <Box mb={2}>
                <Typography variant="h3" component="h1">{intl.formatMessage({ id: "contact_header" })}</Typography>
              </Box>
              <Typography variant="body1">{intl.formatMessage({ id: "contact_msg" })}</Typography>
              <Box mt={2}>
                <ContactForm />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} container>
              <AddressesWrapper>
                <AddressesSource>
                  {({ nodes }) => nodes.map(address => {
                    return (<StyledAddressCard node={address} />)
                  })}
                </AddressesSource>
              </AddressesWrapper>
            </Grid>
          </StyledContainer>
        </Layout>
      </GoogleMapsProvider>
    </BasePage>
  );
}

export default ContactPage;
