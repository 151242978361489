import React, { useEffect, useRef, useContext, useState } from "react";
import GoogleMapsContext from "../google-maps/google-maps-context";
import styled from "styled-components";
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from "gatsby";

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MapPlaceholder = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "map-placeholder.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <StyledBackgroundImage fluid={data.file.childImageSharp.fluid} alt="map placeholder">
      { children }
    </StyledBackgroundImage>
  );
}

const MapError = ({ error, className }) => {
  return <span>{error.message}</span>
};

const Map = ({ className, lat, lng, zoom = 4, showMarker }) => {
  const ref = useRef();
  const loadMaps = useContext(GoogleMapsContext);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(loadMaps){ 
      loadMaps().then(maps => {
        const map = new maps.Map(ref.current, {
          zoom,
          center: { lat, lng },
          disableDefaultUI: true,
          zoomControl: true,
        });
        
        if(showMarker) {
          const marker = new maps.Marker({position: { lat, lng }, map });
        }
      }).catch((err) => {
        setError(err);
      })
      
    }

  }, [loadMaps, lat, lng, zoom, showMarker]);

  return (
    <MapContainer ref={ref} className={className}>
      <MapPlaceholder>
        {error && (
          <MapError error={error} />
        )}
      </MapPlaceholder>
    </MapContainer>
  );
};

export default Map;