import React, { useRef, useMemo, useCallback, useContext } from "react";
import Helmet from "react-helmet";
import GoogleMapsContext from "./google-maps-context";
import { useIntl } from "../intl";
import CookieConsentContext from "../components/cookie-consent/cookie-consent-context";

function makeId(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const GoogleMapsProvider = ({ apiKey, children }) => {
  const cookiesConsented = useContext(CookieConsentContext);
  const intl = useIntl();
  const callbackName = useMemo(() => `initMap_${makeId(5)}`, []);
  const loading = useRef();

  const callback = useCallback(() => {
    if(!loading.current && cookiesConsented) {
      loading.current = new Promise((resolve, reject) => {
        window[callbackName] = () => {
          delete window[callbackName];
          resolve(window.google.maps);
        }
      })
    } else if (!cookiesConsented) {
      return Promise.reject(new Error(intl.formatMessage({ id: "google_maps_cookies_error" })));
    }
    return loading.current;
  }, [callbackName, cookiesConsented, intl]);

  return (
    <>
      { cookiesConsented && (<Helmet>
        <script async defer
          src={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=${callbackName}`}>
        </script>
      </Helmet>) }
      <GoogleMapsContext.Provider value={callback}>
        { children }
      </GoogleMapsContext.Provider>
    </>
  );
};

export default GoogleMapsProvider;